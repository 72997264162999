<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('exam_schedules_double_course_control')"
                        :isColumns="true"
                        @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns" :key="key">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('exam_schedules_double_course_control')"
                              :isColumns="true"
                              @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns" :key="key">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" :filter-button="false" @filterClear="filterClear" :export-excel="checkPermission('examschedule_doublecoursecontrol')" @exportExcel="downloadExcel">
                <ValidationObserver ref="doubleCourseControl">
                    <b-row>
                        <b-col cols="12" sm="6" md="4" lg="3">
                            <ValidationProvider name="academic_year" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('academic_year')">
                                    <AcademicYearsSelectbox v-model="datatable.queryParams.filter.semester_id" modelId/>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" sm="6" md="4" lg="3">
                            <ValidationProvider name="course_code_1" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="`${$t('course_code')}-1`">
                                    <CourseAutoComplete v-model="datatable.queryParams.filter.course_code1"
                                                :state="errors[0] ? false : null" resultType="code"/>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" sm="6" md="4" lg="3">
                            <ValidationProvider name="course_code_2" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="`${$t('course_code')}-2`">
                                    <CourseAutoComplete v-model="datatable.queryParams.filter.course_code2"
                                                :state="errors[0] ? false : null" resultType="code"/>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>

            <CommonModal ref="showModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()" v-if="checkPermission('faculty_show')">
                <template v-slot:CommonModalTitle>
                    {{ `${$t('exam_schedules_double_course_control').toUpper()} ${$t('show').toUpper()}` }}
                </template>
                <template v-slot:CommonModalContent>
                    <show-form :formData="formData"
                               v-if="formProcess=='show'"
                    />
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

// Components
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import CourseAutoComplete from "@/components/interactive-fields/CourseAutoComplete";
// Pages
import ShowForm from "./ShowForm";
// Services
import ExamScheduleService from "@/services/ExamScheduleService";

// Others
import {ValidationProvider, ValidationObserver} from "vee-validate"
import qs from 'qs'
import SurveyService from "@/services/SurveyService";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        CommonModal,
        ShowForm,
        AcademicYearsSelectbox,
        ParameterSelectbox,
        ValidationProvider,
        ValidationObserver,
        CourseAutoComplete
    },
    metaInfo() {
        return {
            title: this.$t('double_course_control')
        }
    },
    data() {
        return {
            statusData:[
                {value: null, text: ''},
                {value: 'a', text: this.$t('active')},
                {value: 'p', text: this.$t('passive')}
            ],
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('show'),
                                class: 'ri-search-eye-line',
                                permission: 'examschedule_doublecoursecontrol',
                                callback: (row) => {
                                    this.showForm(row);
                                },
                            }
                        ]
                    },
                    {
                        label: this.toUpperCase('semester_1'),
                        field: 'academic_year',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('course_code'),
                        field: 'course_code1',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('course_code'),
                        field: 'course_code2',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('student_number_long'),
                        field: 'student_number',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('name_surname'),
                        field: 'name',
                        hidden: false,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'code',
                    page: 1,
                    limit: 20
                }
            },
            formProcess: null,
            formData: null,
            form: {},
        }
    },
    created() {
        this.filterSet();
        this.formClear();
    },
    methods: {
        filterSet() {
            this.datatable.queryParams.filter = {
                code:null,
                name:null,
            }
        },
        filterClear() {
            this.filterSet()
            this.getRows();
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
       async getRows() {
            const isValid = await this.$refs.doubleCourseControl.validate();
            if(!isValid) {
                return
            }

            this.datatable.showTable = true;
            this.datatable.isLoading = true;
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return ExamScheduleService.examSchedulesDoubleCourseControl(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.rows.forEach((c) => {
                        c.academic_year = `${c.academic_year}/${c.semester}`
                        c.name = `${c.name} ${c.surname}`
                    })
                    // this.datatable.total = response.data.pagination.total
                    })
                    .catch((error) => {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    })
                    .finally(() => {
                        this.datatable.isLoading = false;
                    });
        },

        // Clear
        formClear() {
            this.formData = {}
            this.formProcess = null
        },
        // Show
        showForm(data) {
            this.formData = data
            this.formProcess = 'show'
            this.$refs.showModal.$refs.commonModal.show()
        },
      async  downloadExcel() {
            const isValid = await this.$refs.doubleCourseControl.validate();
            if (isValid) {
                const config = {
                    params: {
                        filter:{
                            ...this.datatable.queryParams.filter
                        }
                    },
                    paramsSerializer: (params) =>
                        qs.stringify(params, { encode: false }),
                };
                ExamScheduleService.downloadAsExcel1(config)
                    .then((response) => {
                        this.$toast.success(this.$t('api.' + response.data.message))
                        this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                    }).catch(err=>{
                    this.showErrors(err)
                })
            }
        }
    }
}
</script>

